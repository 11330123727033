<template>
  <Dialog
    :header="$t('calendar.auditEvents')"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '65vw' }"
  >
    <AddAuditSection
      :caseData="caseSelected"
      :display="viewAddAudit"
      @closeAudit="closeAddAudit"
      @updateData="updateSections"
    >
    </AddAuditSection>
    <div class="grid flex-column grid-nogutter">
      <div class="col" v-if="loading" style="text-align: center">
        <ProgressSpinner style="width: 50px; height: 50px" />
      </div>
      <div class="col" v-if="caseSelected.companyName">
        <DateAndCompanyInfo :data="caseSelected"></DateAndCompanyInfo>
      </div>
      <div class="col">
        <h3>{{ $t("calendar.auditPlans") }}</h3>
      </div>
      <div class="col" v-if="checkRole(['client'])">
        <p>{{ $t("calendar.reviewPlan") }}</p>
      </div>

      <div class="col">
        <AuditSectionsTable
          :caseData="caseSelected"
          :loadingValue="loading"
          :showError="showError"
        ></AuditSectionsTable>
      </div>
      <div
        class="col flex align-items-center"
        :class="showError ? 'justify-content-between' : 'justify-content-end'"
        v-if="!checkRole(['client'])"
        style="margin-top: 1%"
      >
        <span class="p-error" v-show="showError"
          >*{{ $t("cases.addOneAuditSection") }}</span
        >
        <Button
          :label="$t('calendar.addAudit')"
          @click="showAddAudit"
          class="p-button-warning"
          v-if="
            caseSelected.status == CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name ||
            caseSelected.status == CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.name
          "
        >
        </Button>
      </div>
    </div>
    <template #footer>
      <!-- <Button
        :label="$t('global.save')"
        iconPos="right"
        :loading="buttonLoading"
        @click="save"
        class="p-button-success"
      /> -->
      <Button
        :label="$t('global.send')"
        iconPos="right"
        :loading="sendButtonLoading"
        @click="send"
        class="p-button-success"
        v-if="
          caseSelected.status == CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name ||
          caseSelected.status == CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.name
        "
      />
      <Button
        :label="
          caseSelected.status == CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name ||
          caseSelected.status == CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.name
            ? $t('global.cancel')
            : $t('global.close')
        "
        iconPos="right"
        :loading="buttonLoading"
        @click="close(true)"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import DateAndCompanyInfo from "../Cases/DateAndCompanyInfo.vue";
import AddAuditSection from "./AddAuditSection.vue";
import AuditSectionsTable from "./AuditSectionsTable.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Add event",
  components: {
    DateAndCompanyInfo,
    AddAuditSection,
    AuditSectionsTable,
  },
  props: ["display", "dataValue"],
  emits: ["closePlan"],
  data() {
    return {
      viewDialog: false,
      caseSelected: {},
      viewAddAudit: false,
      loading: true,
      sendButtonLoading: false,
      buttonLoading: false,
      showError: false,
    };
  },
  computed: {
    ...mapGetters([
      "cases",
      "caseById",
      "auditPlans",
      "auditCaseById",
      "checkRole",
    ]),
    ...mapState(["CaseStatuses"]),
    data() {
      return this.dataValue;
    },
  },
  watch: {
    async display(val) {
      console.log(this.data);
      this.viewDialog = val;
      if (val) {
        console.log(this.dataValue);
        this.loading = true;
        const eventData = this.data.event;
        const caseId = eventData.id;
        // await this.$store.dispatch("caseAndApplicationForPlan", {
        //   planId,
        //   caseId: eventData.extendedProps.id,
        // });
        this.caseSelected = { ...this.auditCaseById(caseId) };
        console.log(this.caseSelected);

        this.loading = false;
      }
    },
    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        // this.loading = true;
        // await this.$store.dispatch("permissions");
        // this.loading = false;
      }
    },
  },
  methods: {
    close(success = false) {
      this.$emit("closePlan", success);
      this.caseSelected = {};
      this.showError = false;
    },
    save() {},
    async send() {
      try {
        if (this.caseSelected.auditPlan.auditSections.length == 0) {
          this.showError = true;
        } else {
          this.sendButtonLoading = true;
          await this.$store
            .dispatch("updateAuditPlan", {
              id: this.caseSelected.auditPlan.id,
              version: this.caseSelected.auditPlan.version,
              updateObject: {
                status: "send",
                auditors: this.caseSelected.auditPlan.auditors,
                auditDate: this.caseSelected.auditPlan.auditDate,
                auditDays:
                  this.$dayjs(
                    this.caseSelected.auditPlan.auditDateEnd,
                    "DD/MM/YYYY"
                  ).diff(
                    this.$dayjs(
                      this.caseSelected.auditPlan.auditDate,
                      "DD/MM/YYYY"
                    ),
                    "d"
                  ) + 1,
              },
            })
            .then(() => {
              this.$notification(
                this.$t("calendar.updatePlan"),
                this.$t("calendar.planUpdated")
              );
              this.$action(
                "audit-schedule-send",
                {
                  caseId: this.caseSelected.id,
                  applicationId: this.caseSelected.application.id,
                },
                "case"
              );
            });
          this.sendButtonLoading = false;
          this.close(true);
        }
      } catch {
        this.sendButtonLoading = false;
      }
    },
    showAddAudit() {
      this.viewAddAudit = true;
    },
    closeAddAudit() {
      this.viewAddAudit = false;
      if (this.caseSelected.auditPlan.auditSections.length > 0) {
        this.showError = false;
      }
    },
    updateSections() {
      this.caseSelected = { ...this.auditCaseById(this.data.event.id) };
    },
  },
};
</script>
