<template>
  <CaseView
    :dataValue="selectedItem"
    :display="viewCase"
    @closeCase="closeCase"
  ></CaseView>

  <PlanView
    :dataValue="selectedItem"
    :display="viewAuditPlan"
    @closePlan="closePlanView"
  >
  </PlanView>
  <!-- 
  <TaskView
    :display="viewTask"
    :dataValue="selectedItem"
    @closeTask="closeTask"
  >
  </TaskView> -->
  <ProgressSpinner
    v-if="loading || mountLoad"
    style="
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    "
  />
  <div>
    <div class="grid align-items-stretch vertical-container">
      <div class="col h-auto">
        <Card>
          <template #content>
            <FullCalendar
              style="z-index: 0"
              ref="fullCalendar"
              :events="getCalendarEvents"
              :options="options"
            />
          </template>
        </Card>
        <!-- <Card >
        <template #content style="min-height:60%">
                <div >

                </div>
        </template>
      </Card> -->
      </div>
      <div class="xl:col-3 col-12 md:col-4 lg:col-4">
        <Card class="w-12 mb-5 h-auto">
          <template #title> {{ $t("calendar.auditEvents", 2) }} </template>
          <!-- <template #content v-if="checkRole(['auditor'])">
            <div class="grid">
              <div class="col-1">
                <div id="green-dot" class="dot"></div>
              </div>
              <div class="col">
                <div>{{ $t("calendar.plannedAuditDates") }}</div>
              </div>
            </div>

            <div class="grid">
              <div class="col-1">
                <div id="blue-dot" class="dot"></div>
              </div>
              <div class="col">
                <div>{{ $t("calendar.assignedAuditDates") }}</div>
              </div>
            </div>
          </template> -->
          <template #content>
            <div class="text-center" v-if="loading || mountLoad">
              <ProgressSpinner />
            </div>
            <template
              v-else
              v-for="(status, index) in CaseStatuses"
              :key="index"
            >
              <template
                v-if="getCalendarCases.find((el) => el.status == status.name)"
              >
                <div class="grid">
                  <div class="col-1">
                    <div
                      :style="{
                        'background-color': status.color,
                      }"
                      class="dot"
                    ></div>
                  </div>
                  <div class="col">
                    <div>
                      <template
                        v-if="
                          checkRole(['client']) &&
                          status.name != CaseStatuses.EVENT_CREATED.name &&
                          status.name != CaseStatuses.SCHEDULE_CREATED.name &&
                          status.name !=
                            CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name &&
                          status.name != CaseStatuses.CERTIFIED.name
                        "
                      >
                        {{ $t("calendar.processing") }}
                      </template>
                      <template v-else>
                        {{ translatedStatus(status.name) }}
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <template
            #footer
            v-if="checkRole(['head-audit-planner', 'audit-planner', 'admin'])"
          >
            <div class="grid flex-column">
              <div class="col">
                <Button
                  :label="'+ ' + $t('calendar.addNewEvent')"
                  class="p-button-success"
                  style="width: 100%"
                  @click="openView({})"
                />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { mapGetters, mapState } from "vuex";
import CaseView from "./CaseView.vue";
import PlanView from "./AuditPlanView.vue";
// import TaskView from "./TaskView.vue";
// import EventService from './service/EventService';
export default {
  name: "Calendar",
  components: {
    CaseView,
    PlanView,
    // TaskView,
  },
  data() {
    return {
      selectedItem: {},
      viewCase: false,
      viewAuditPlan: false,
      viewTask: false,
      loading: false,
      mountLoad: false,
    };
  },
  computed: {
    ...mapGetters([
      "checkPermission",
      "getCalendarCases",
      "getCalendarPlans",
      "getCalendarTasks",
      "checkRole",
      "checkAuditorsAvailability",
      "translatedStatus",
    ]),
    ...mapState(["CaseStatuses"]),
    options() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "",
        },
        // editable: true,
        // selectable: true,
        droppable: false,
        selectMirror: true,
        dayMaxEvents: true,
        moreLinkClick: (data) => {
          console.log(data);
        },
        dayCellDidMount: () => {
          // console.log(data);
          // console.log(this.$dayjs(data.date).format("YYYY-MM-DD"));
          // console.log(data.el.children[0].children[0]);
          // let header = data.el.children[0].children[0];
          // header.style.cssText ="flex-direction: initial; justify-content:space-between";
          // if(this.checkAuditorsAvailability(this.$dayjs(data.date).format("YYYY-MM-DD")) && data.view.type == "dayGridMonth") {
          //   header.classList.add(...["flex-row", "justify-content-between"]);
          //   header.classList.add("justify-content-between");
          //   header.insertAdjacentHTML(
          //     "afterbegin",
          //     '<div class="auditorAvailability align-self-center mx-1 w-1rem h-1rem" style="background-color:#800080"></div>'
          //   );
          // }
          // document
          //   .querySelectorAll(".auditorAvailability")
          //   .forEach((e) => e.remove());
        },
        dayCellWillUnmount: () => {
          // document
          //   .querySelectorAll(".auditorAvailability")
          //   .forEach((e) => e.remove());
        },
        datesSet: async () => {
          const dataCalendar = this.$refs.fullCalendar.calendar.view;
          if (!this.checkRole(["client"])) {
            this.loading = true;
            document
              .querySelectorAll(".auditorAvailability")
              .forEach((e) => e.remove());
            await this.$store.dispatch("auditorsAvailability", {
              startDate: this.$dayjs(dataCalendar.activeStart).format(
                "DD/MM/YYYY"
              ),
              endDate: this.$dayjs(dataCalendar.activeEnd).format("DD/MM/YYYY"),
            });
            document
              .querySelectorAll(".auditorAvailability")
              .forEach((e) => e.remove());
            document.querySelectorAll(".fc-daygrid-day").forEach((el) => {
              let header = el.children[0].children[0];
              if (
                this.checkAuditorsAvailability(
                  this.$dayjs(el.attributes["data-date"].value).format(
                    "DD/MM/YYYY"
                  )
                ) &&
                dataCalendar.type == "dayGridMonth"
              ) {
                header.classList.add(
                  ...["flex-row", "justify-content-between"]
                );
                header.classList.add("justify-content-between");
                header.insertAdjacentHTML(
                  "afterbegin",
                  '<div class="auditorAvailability align-self-center mx-1 w-1rem h-1rem" style="background-color:#800080"></div>'
                );
              }
            });
            this.loading = false;
          }
        },
        eventClick: (item) => {
          console.log(item);
          if (document.querySelector(".fc-more-popover")) {
            document.querySelector(".fc-more-popover").remove();
          }
          if (
            this.checkRole([
              "certification-manager",
              "quality-assurance-manager",
              "product-manager",
            ])
          ) {
            this.$router.push({
              name: "cases",
              query: { caseId: item.event.id },
            });
          } else if (this.checkRole(["client"])) {
            if (
              item.event.extendedProps.status ==
                this.CaseStatuses.EVENT_CREATED.name ||
              item.event.extendedProps.status ==
                this.CaseStatuses.SCHEDULE_CREATED.name
            ) {
              console.log(item);
              this.$router.push({
                name: "cases",
                query: { caseId: item.event.id },
              });
            }
          } else if (
            item.event.extendedProps.status !=
            this.CaseStatuses.EVENT_CREATED.name
          ) {
            this.openView(item);
          }
        },
        height: "70vh",
        locale: this.$i18n.locale,
      };
    },
    getCalendarEvents() {
      // if (this.checkRole(["client"]) || this.checkRole(["auditor"])) {
      //   if(this.checkRole(["client"])) {
      //     return this.getCalendarPlans;
      // }else if(this.checkRole(["audit-planner"])) {
      //   return this.getCalendarCases;
      // }

      // if (this.checkRole(["client"])) {
      //   let tempCases = [...this.getCalendarCases];
      //   tempCases.forEach((el) => {
      //     if (
      //       el.status != this.CaseStatuses.EVENT_CREATED.name &&
      //       el.status != this.CaseStatuses.SCHEDULE_CREATED.name &&
      //       el.status != this.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name &&
      //       el.status != this.CaseStatuses.CERTIFIED.name
      //     ) {
      //       el.title = this.$t("calendar.processing");
      //     }
      //   });
      // }

      return [...this.getCalendarCases];
    },
  },
  mounted() {
    this.getCalendarInfo();
    if (this.checkRole(["accounting"])) {
      this.$action("assigned-audits-page-view");
    } else {
      this.$action("calendar-page-view");
    }
  },
  methods: {
    async getCalendarInfo() {
      // const dataCalendar = this.$refs.fullCalendar.calendar.view;

      this.mountLoad = true;
      // if (this.checkRole(["audit-planner", "head-audit-planner"])) {
      //   Promise.all([
      //     await this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.CREATE_PLAN.name,
      //     }),
      //     await this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.EVENT_REJECTED_BY_CLIENT.name,
      //     }),
      //   ])
      // } else if (this.checkRole(["auditor"])) {
      //   await this.$store.dispatch("cases", {
      //     status: this.CaseStatuses.TO_BE_AUDITED.name,
      //   });
      // } else if (this.checkRole(["manager-for-audit-assignment","head-manager-for-audit-assignment"])) {
      //   await Promise.all([
      //     this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.name,
      //     }),
      //     this.$store.dispatch("cases", {
      //       status: this.CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name,
      //     }),
      //   ]);
      // } else {
      //   }
      await this.$store.dispatch("cases", {});
      // await this.$store.dispatch("auditPlans");
      this.mountLoad = false;
      if ("caseId" in this.$route.query && this.$route.query.caseId != "") {
        let events = this.$refs.fullCalendar.calendar.getEvents();
        this.openView({
          event: events.find((el) => el.id == this.$route.query.caseId),
        });
      }
    },
    openView(item) {
      this.selectedItem = {};
      // if ("event" in item && "auditTasks" in item.event.extendedProps) {
      //   this.viewAuditPlan = true;
      //   console.log("Plan");
      // } else {
      //   this.viewCase = true;
      //   console.log("Case");
      // }
      if (Object.keys(item).length == 0) {
        this.viewCase = true;
      } else {
        if (
          item.event.extendedProps.status ==
            this.CaseStatuses.CREATE_PLAN.name ||
          item.event.extendedProps.status ==
            this.CaseStatuses.DATE_OVERDUE.name ||
          item.event.extendedProps.status ==
            this.CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.name ||
          item.event.extendedProps.status ==
            this.CaseStatuses.EVENT_REJECTED_BY_CLIENT.name
        ) {
          this.viewCase = true;
        } else if (
          item.event.extendedProps.status ==
            this.CaseStatuses.EVENT_ACCEPTED_BY_CLIENT.name ||
          item.event.extendedProps.status ==
            this.CaseStatuses.TO_BE_AUDITED.name ||
          item.event.extendedProps.status ==
            this.CaseStatuses.TO_BE_ACCEPTED_BY_CLIENT.name ||
          item.event.extendedProps.status ==
            this.CaseStatuses.SCHEDULE_REJECTED_BY_CLIENT.name
        ) {
          this.viewAuditPlan = true;
        }
      }

      this.selectedItem = item;
    },
    closeCase(value) {
      if (value) {
        this.getCalendarInfo();
      }
      this.viewCase = false;
      this.selectedItem = {};
      this.$router.replace({ query: null });
    },
    closePlanView(value) {
      if (value) {
        this.getCalendarInfo();
      }
      this.viewAuditPlan = false;
      this.selectedItem = {};
      this.$router.replace({ query: null });
    },
    closeTask(value) {
      if (value) {
        this.getCalendarInfo();
      }
      this.viewTask = false;
      this.selectedItem = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 0 !important;
}

#purple-dot {
  background-color: purple;
}

.grid {
  height: 100% !important;
}

::v-deep(.fc) {
  .fc-event {
    cursor: pointer;
  }
}
</style>
