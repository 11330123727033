<template>
  <Dialog
    :header="`${$t('calendar.auditEvents')}  ${
      caseSelected.id ? `#${caseSelected.id}` : ''
    }`"
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '55vw' }"
  >
    <div class="grid flex-column">
      <div class="col p-fluid" v-if="Object.keys(data).length == 0">
        <Dropdown
          v-model="caseSelected"
          :options="dialogCases"
          @change="selectCase"
          :loading="loadingDropDown"
        >
          <template #option="slotprops">
            <div class="flex align-items-center">
              <div>
                {{ $t("calendar.auditCases") }} #{{ slotprops.option.id }}
              </div>
              |
              <div>
                {{ slotprops.option.auditDate }}
              </div>
            </div>
          </template>

          <template #value="slotprops">
            <div
              class="flex align-items-center"
              v-if="Object.keys(slotprops.value).length != 0"
            >
              <div>
                {{ $t("calendar.auditCases") }} #{{ slotprops.value.id }}
              </div>
              |
              <div>
                {{ slotprops.value.auditDate }}
              </div>
            </div>
            <span v-else>
              {{ $t("calendar.selectCase") }}
            </span>
          </template>
        </Dropdown>
      </div>
      <div class="col" v-if="loading" style="text-align: center">
        <ProgressSpinner style="width: 50px; height: 50px" />
      </div>
      <!-- <div class="col" v-if="caseSelected.application">
        <InputText
          style="width: 60%"
          v-model="caseSelected.application.companyName"
          :readonly="true"
        ></InputText>
      </div>
      <div class="col">
        <div class="grid grid-nogutter" v-if="caseSelected.auditDate">
          <div class="col-4">
            <InputText
              v-model="caseSelected.auditDate"
              :readonly="true"
            ></InputText>
          </div>
          <div class="col-3" style="align-self: center">
            <b>{{ $t("calendar.certificationType") }}</b
            >:
          </div>
          <div class="col" style="align-self: center">type</div>
        </div>
      </div> -->
      <div class="col" v-if="caseSelected.companyName">
        <div class="grid flex-column">
          <div class="col-12 text-center">
            <Message
              v-if="CaseStatuses.DATE_OVERDUE.name == caseSelected.status"
              severity="error"
              >{{ $t("calendar.auditDateError") }}</Message
            >
          </div>
          <div class="col-12">
            <span class="text-xl font-bold">
              {{ caseSelected.companyName }}
            </span>
          </div>
          <div class="col-12 grid grid-nogutter align-items-center">
            <div class="col-12 lg:col-3">
              <Calendar
                :minDate="new Date()"
                v-model="caseSelected.date"
                selectionMode="range"
                :manualInput="false"
                dateFormat="dd/mm/yy"
                @hide="getAuditors"
                :class="{
                  'p-invalid': v$.caseSelected.date.$invalid && submitted,
                }"
              />
              <ul
                v-if="v$.caseSelected.date.$error && submitted"
                class="p-error text-xs col-12 pt-1 pl-3 m-0"
              >
                <li
                  v-for="(error, index) of v$.caseSelected.date.$errors"
                  :key="index"
                >
                  {{ $t("calendar.auditDateError") }}
                </li>
              </ul>
            </div>
            <div
              class="col-6 lg:col-3 text-left lg:text-center font-bold text-xl"
            >
              <span>{{ $t("cases.certificationType") }}:</span>
            </div>
            <div class="col-6 lg:col-6 text-left lg:text-center text-lg">
              <span>{{ caseSelected.certification.schemeTitle }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col" v-if="caseSelected.company">
        <h3></h3>
      </div> -->
      <PickList
        v-if="
          caseSelected.companyName &&
          (caseSelected.status == CaseStatuses.CREATE_PLAN.name ||
            caseSelected.status == CaseStatuses.DATE_OVERDUE.name ||
            caseSelected.status ==
              CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.name ||
            caseSelected.status == CaseStatuses.EVENT_REJECTED_BY_CLIENT.name)
        "
        v-model="dataAuditors"
        listStyle="height:342px"
        dataKey="id"
        @selection-change="checkSelected"
      >
        <template #sourceheader>
          {{ $t("calendar.availableAuditors") }}
        </template>
        <template #targetheader>
          {{ $t("calendar.assignedAuditors") }}
        </template>
        <template #item="slotProps">
          <div
            v-if="
              slotProps.item.doesNotExist && slotProps.item.available == false
            "
            style="color: red"
          >
            <span class="mdi mdi-account-alert mid-24px"></span>
            {{ slotProps.item.username }}
            <span style="font-size: 10px">{{
              $t("calendar.notAvailable")
            }}</span>
          </div>
          <div v-else>
            {{ slotProps.item.username }}
          </div>
        </template>
      </PickList>
      <!-- <div class="col" v-if="caseSelected.company">
        <h3></h3>
      </div> -->
      <div
        class="col"
        v-if="caseSelected.status == CaseStatuses.EVENT_CREATED.name"
      >
        <div class="grid flex-column grid-nogutter">
          <div
            v-for="(auditor, index) in caseSelected.auditors"
            :key="index"
            class="col"
          >
            <div class="grid grid-nogutter">
              <div class="col-3">
                <b>{{ $t("calendar.fullName") }}:</b>
                {{ auditor.firstName }} {{ auditor.lastName }}
              </div>

              <div class="col-3">
                <b>{{ $t("calendar.email") }}:</b>
                {{ auditor.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer v-if="caseSelected.companyName">
      <Button
        :label="$t('calendar.assign')"
        iconPos="right"
        :loading="buttonLoading"
        :disabled="disableAssign"
        @click="assign"
        class="p-button-success"
      />
    </template>
  </Dialog>
</template>

<script>
// import DateAndCompanyInfo from "../Cases/DateAndCompanyInfo.vue";
import { mapGetters, mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Add event",
  emits: ["closeCase"],
  components: {
    // DateAndCompanyInfo,
  },
  props: ["display", "dataValue"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      caseSelected: {},
      loadingDropDown: false,
      loading: true,
      buttonLoading: false,
      dataAuditors: null,
      disableAssign: true,
      originalAuditDate: "",
      originalAuditDateEnd: "",
      submitted: false,
    };
  },
  mounted() {
    // console.log(this.auditors);
    // console.log(this.dataAuditors);
    // this.dataAuditors = [this.availableAuditors, []];
  },
  computed: {
    ...mapGetters([
      "cases",
      "caseById",
      "availableAuditors",
      "dialogCases",
      "checkRole",
    ]),
    ...mapState(["CaseStatuses"]),
    data() {
      return this.dataValue;
    },
  },
  validations() {
    return {
      caseSelected: {
        date: {
          checkMinValue: this.checkMinValue,
        },
      },
    };
  },
  watch: {
    async display(val) {
      this.viewDialog = val;
      this.loading = true;
      if (val && Object.keys(this.data).length == 0) {
        this.loadingDropDown = true;
        await this.$store.dispatch(
          "dialogCases",
          this.CaseStatuses.CREATE_PLAN.name
        );
        // this.cases.forEach(async (el) => {
        //   await this.$store.dispatch("certificationForCase", {
        //     caseId: el.id,
        //     certificationId: el.certificationId,
        //   });
        //   await this.$store.dispatch("applicationForCase", {
        //     caseId: el.id,
        //     applicationId: el.applicationId,
        //   });
        //   // el.auditors.forEach(async (el1) => {
        //   //   await this.$store.dispatch("userById", {
        //   //     caseId: el.id,
        //   //     userId: el1,
        //   //   });
        //   // });
        // });
        this.loadingDropDown = false;
      } else if (val && Object.keys(this.data).length > 0) {
        console.log(this.data.event.extendedProps);
        const eventData = this.data.event;
        console.log(eventData.auditTasks);

        const caseId = eventData.id;
        // await this.$store.dispatch("certificationForCase", {
        //   caseId,
        //   certificationId: this.data.event.extendedProps.certificationId,
        // });
        // await this.$store.dispatch("applicationForCase", {
        //   caseId,
        //   applicationId: this.caseById(caseId).applicationId,
        // });

        // Promise.all(
        //   await this.caseById(caseId).auditors.map(async (el1) => {
        //     await this.$store.dispatch("userById", {
        //       caseId,
        //       userId: el1,
        //     });
        //   })
        // ).then(() => {
        //   console.log(this.caseById(caseId));
        // });
        this.caseSelected = { ...this.caseById(caseId) };
        this.caseSelected.date = [
          this.$dayjs(this.caseSelected.auditDate, "DD/MM/YYYY").toDate(),
          this.$dayjs(this.caseSelected.auditDateEnd, "DD/MM/YYYY").toDate(),
        ];
        this.getAuditors();
        // this.caseSelected.date = new Date(this.caseSelected.date);
      }
      this.loading = false;
    },

    async viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        console.log(this.caseSelected);
        this.originalAuditDate = this.caseSelected.auditDate;
        this.originalAuditDateEnd = this.caseSelected.auditDateEnd;
        this.$nextTick(() => {
          const sourceControls = document.querySelector(
            ".p-picklist-source-controls"
          );
          const targetControls = document.querySelector(
            ".p-picklist-target-controls"
          );
          if(sourceControls) sourceControls.style.display = "none";
          if(targetControls) targetControls.style.display = "none";
        });

        // this.loading = true;
        // await this.$store.dispatch("permissions");
        // this.loading = false;
      }
    },
  },
  methods: {
    checkMinValue() {
      let result = true;
      for (const el of  this.caseSelected.date) {
        if (this.$dayjs().isAfter(this.$dayjs(el), "date")) {
          result = false;
          break;
        }
      }
      return result;
    },
    checkSelected() {
      if (this.dataAuditors[1].length == 0) {
        this.disableAssign = true;
      } else {
        this.disableAssign = false;
      }

      this.dataAuditors[1].forEach((el) => {
        if (el.doesNotExist) {
          this.disableAssign = true;
        }
      });
    },
    async getAuditors() {
      if (this.caseSelected.date[1] == null) {
        this.caseSelected.date[1] = this.caseSelected.date[0];
      }
      await this.$store.dispatch("availableAuditors", {
        startDate: this.$dayjs(this.caseSelected.date[0]).format("DD/MM/YYYY"),
        endDate: this.$dayjs(this.caseSelected.date[1]).format("DD/MM/YYYY"),
      });
      this.dataAuditors = [
        this.availableAuditors,
        this.caseSelected.auditors ? [...this.caseSelected.auditors] : [],
      ];

      this.dataAuditors[0].forEach((el) => {
        el.available = true;
      });

      this.dataAuditors[1].forEach((el) => {
        el.available = false;
        el.doesNotExist = false;
      });

      this.dataAuditors[1].forEach((el) => {
        if (!this.dataAuditors[0].find((el1) => el1.id == el.id)) {
          if (
            this.originalAuditDate !=
              this.$dayjs(this.caseSelected.date[0]).format("DD/MM/YYYY") &&
            this.originalAuditDateEnd !=
              this.$dayjs(this.caseSelected.date[1]).format("DD/MM/YYYY")
          ) {
            el.doesNotExist = true;
          }
        }
      });
      if (this.dataAuditors[0].length > 0) {
        this.dataAuditors[1].forEach((el) => {
          if (this.dataAuditors[0].find((el1) => el1.id == el.id)) {
            this.dataAuditors[0].splice(
              this.dataAuditors[0].findIndex((el1) => el1.id == el.id),
              1
            );
          }
        });
      }

      this.checkSelected();
    },
    close(success = false) {
      this.$emit("closeCase", success);
      this.caseSelected = {};
      this.submitted = false;
    },
    selectCase(value) {
      console.log(value);
      this.caseSelected = { ...value.value };
      this.caseSelected.date = [
        this.$dayjs(this.caseSelected.auditDate, "DD/MM/YYYY").toDate(),
        this.$dayjs(this.caseSelected.auditDateEnd, "DD/MM/YYYY").toDate(),
      ];
      this.getAuditors();
    },
    // datesDiff(date1, date2) {
    //   date1 = new Date(date1);
    //   date2 = new Date(date2);
    //   const diffTime = Math.abs(date2 - date1);
    //  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // },
    async assign() {
      this.submitted = true;
      const result = await this.v$.$validate();

      if (result) {
        this.buttonLoading = true;
        if (
          this.checkRole([
            "head-manager-for-audit-assignment",
            "manager-for-audit-assignment",
          ])
        ) {
          const planObject = {
            caseId: this.caseSelected.id,
            auditors: [],
            auditDate: "",
            auditDays: 1,
          };
          if (Array.isArray(this.caseSelected.date)) {
            planObject.auditDate = this.$dayjs(
              this.caseSelected.date[0]
            ).format("DD/MM/YYYY");
            if (this.caseSelected.date[1] != null) {
              // planObject.auditDays = this.datesDiff(this.caseSelected.date[0],this.caseSelected.date[1]);
              planObject.auditDays =
                this.$dayjs(this.caseSelected.date[1]).diff(
                  this.$dayjs(this.caseSelected.date[0]),
                  "d"
                ) + 1;
            }
          } else {
            planObject.auditDate = this.$dayjs(this.caseSelected.date).format(
              "DD/MM/YYYY"
            );
          }

          try {
            this.dataAuditors[1].forEach((el) => {
              planObject.auditors.push(el.id.toString());
            });
            await this.$store
              .dispatch("createAuditPlan", planObject)
              .then(() => {
                this.dataAuditors[1].forEach((el) => {
                  this.$action(
                    "auditor-team-assign",
                    {
                      targetUsername: el.username,
                      caseId: this.caseSelected.id,
                      applicationId: this.caseSelected.application.id,
                    },
                    "case"
                  );
                });
                this.$action("audit-date-set", {
                  caseId: this.caseSelected.id,
                });
              });
            this.$notification(
              this.$t("calendar.createAuditPlan"),
              this.$t("calendar.planCreated")
            );
            this.buttonLoading = false;
            this.close(true);
          } catch (err) {
            this.buttonLoading = false;
          }
        } else if (this.checkRole(["head-audit-planner", "audit-planner"])) {
          const caseObject = {
            id: this.caseSelected.id,
            applicationId: this.caseSelected.application.id,
            auditors: [],
            status: this.CaseStatuses.TO_BE_ACCEPTED_BY_AUDIT_MANAGER.name,
            companyRepresentative: this.caseSelected.companyRepresentative,
            companyName: this.caseSelected.companyName,
            version: this.caseSelected.version,
          };

          if (Array.isArray(this.caseSelected.date)) {
            caseObject.auditDate = this.$dayjs(
              this.caseSelected.date[0]
            ).format("DD/MM/YYYY");
            if (this.caseSelected.date[1] != null) {
              // planObject.auditDays = this.datesDiff(this.caseSelected.date[0],this.caseSelected.date[1]);
              caseObject.auditDays =
                this.$dayjs(this.caseSelected.date[1]).diff(
                  this.$dayjs(this.caseSelected.date[0]),
                  "d"
                ) + 1;

              caseObject.auditDateEnd = this.$dayjs(
                this.caseSelected.date[1]
              ).format("DD/MM/YYYY");
            } else {
              caseObject.auditDateEnd = this.$dayjs(
                this.caseSelected.date[0]
              ).format("DD/MM/YYYY");
            }
          } else {
            caseObject.auditDate = this.$dayjs(this.caseSelected.date).format(
              "DD/MM/YYYY"
            );
            caseObject.auditDateEnd = this.$dayjs(
              this.caseSelected.date
            ).format("DD/MM/YYYY");
          }

          this.dataAuditors[1].forEach((el) => {
            caseObject.auditors.push(el.id.toString());
          });

          await this.$store.dispatch("updateCase", caseObject).then(() => {
            this.dataAuditors[1].forEach((el) => {
              this.$action("auditor-select", { targetUsername: el.username });
            });
            this.$action(
              "audit-date-select",
              {
                caseId: caseObject.id,
                applicationId: caseObject.applicationId,
              },
              "both"
            );
          });
        }
        this.buttonLoading = false;
        this.close(true);
      }
    },
  },
};
</script>
